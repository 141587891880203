import React from 'react'
import { Spin } from 'antd'

const Splash = () => {
    return (
        <div className='flex flex-col items-center justify-center h-[80vh] w-full'>
            <div className='flex justify-center'>
                <img src="https://utfs.io/f/wzoc0SJJ8iMqmv8tcTUCV0hUWxjMFoDS38r1AKmZ5kftGwsN" width={250} alt='ConnectedBytes Logo' />
            </div>
            <Spin />
        </div>
    )
}

export default Splash