import { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header'
import Footer from './components/Footer';
import Home from './pages/Home';
import AllPosts from './pages/AllPosts';
import SinglePost from './pages/SinglePost';
import ImageLoader from './components/ImageLoader';

function App() {

  const NotFound = () => {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-red-500">404</h1>
          <p className="mt-4 text-xl text-gray-700">Oops! The page you are looking for does not exist.</p>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* page frame */}
      <div className='flex flex-col'>

        {/* top menu */}
        <Header />

        {/* routing frame */}
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/case-studies' element={<AllPosts />} />
          <Route path='/case-studies/:slug' element={<SinglePost />} />
          <Route path="*" element={<NotFound />} />
        </Routes>


        {/* footer */}
        <Footer />

      </div >

    </>
  )
}

export default App
