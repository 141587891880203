import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from "react-helmet-async";
import Splash from '../components/Splash';
import RenderGutenberg from './../components/RenderGutenberg';

const SinglePost = () => {
  const { slug } = useParams();

  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState(null)
  const [error, setError] = useState(null)

  // Default meta tags in case of loading or error
  const defaultMetaTags = {
    title: 'Connected Bytes',
    description: 'Explore our case studies and insights',
    image: 'https://utfs.io/f/wzoc0SJJ8iMqmv8tcTUCV0hUWxjMFoDS38r1AKmZ5kftGwsN',
    url: 'https://connectedbytes.in'
  }

  useEffect(() => {
    fetchData()
    window.scrollTo(0, 0);
  }, [slug])

  const fetchData = () => {
    setLoading(true)
    axios.get(`posts.php/get-posts/${slug}`)
      .then((response) => {
        setDetails(response.data)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setError(err)
        setLoading(false)
      });
  }

  // Dynamic meta tag generation
  const getMetaTags = () => {
    if (loading || !details) return defaultMetaTags;

    return {
      title: details.title.rendered,
      description: details.acf.SEO_excerpt_posts,
      image: details.acf.seo_image_posts,
      url: `https://connectedbytes.in/case-studies/${slug}`
    }
  }

  const metaTags = getMetaTags();

  return (
    <>
      {loading ? (
        <Splash />
      ) : error ? (
        <div className="error-container">
          <p>Unable to load post. Please try again later.</p>
        </div>
      ) : (
        <>
          <Helmet>
            {/* Dynamic Open Graph Tags */}
            <title>{metaTags.title}</title>
            <meta property="og:title" content={metaTags.title} />
            <meta property="og:description" content={metaTags.description} />
            <meta property="og:image" content={metaTags.image} />
            <meta property="og:url" content={metaTags.url} />
            <meta property="og:type" content="article" />

            {/* Additional Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metaTags.title} />
            <meta name="twitter:description" content={metaTags.description} />
            <meta name="twitter:image" content={metaTags.image} />
          </Helmet>

          <div className='page-container pb-[25px] pt-[10px]'>
            <div className='pb-[25px] pt-[15px]'>
              <p className='font-secondary text-subheading text-center'>
                {details.title.rendered}
              </p>
              <div className='flex justify-center pb-[10px]'>
                <div className='w-[50px] border-[3px] border-secondary rounded' />
              </div>
            </div>
          </div>

          <div className='page-container pb-[50px] pt-[10px]'>
            <RenderGutenberg content={details.content.rendered} />
          </div>
        </>
      )}
    </>
  )
}

export default SinglePost