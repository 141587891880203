import React, { useState, useEffect } from 'react';
import { Skeleton } from 'antd';

const ImageLoader = ({ url, ht }) => {
    const [loading, setLoading] = useState(true);
    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.src = url;

        image.onload = () => {
            setLoading(false);
            setImageError(false);
        };

        image.onerror = () => {
            setLoading(false);
            setImageError(true);
        };

        return () => {
            image.onload = null;
            image.onerror = null;
        };
    }, [url]);

    if (imageError) {
        return <div style={{ height: ht }}>Image failed to load</div>;
    }

    return (
        <div>
            {loading ? (
                <div
                    style={{ height: ht }}
                    className='flex justify-center items-center'
                >
                    <Skeleton.Image active />
                </div>
            ) : (
                <img
                    className='flex justify-center items-center w-full'
                    src={url}
                    alt="Loaded image"
                    style={{
                        width: 'auto',
                        objectFit: 'contain'
                    }}
                />
            )}
        </div>
    );
};

export default ImageLoader;