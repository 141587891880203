import React from 'react'
import DOMPurify from 'dompurify'
import './rendergutenberg.css'
const RenderGutenberg = ({ content }) => {

    //  console.log(content)

    const clean = DOMPurify.sanitize(content)

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: clean }} />
        </div>
    )
}

export default RenderGutenberg