import React from 'react';
import { Cloud } from 'react-icon-cloud';

const icons = [
    {
        name: "React",
        src: "https://cdn.simpleicons.org/react/61DAFB",
        link: "https://reactjs.org",
    },
    {
        name: "Apple",
        src: "https://cdn.simpleicons.org/apple/000000",
        link: "https://www.apple.com",
    },
    {
        name: "Android",
        src: "https://cdn.simpleicons.org/android/3DDC84",
        link: "https://www.android.com",
    },
    {
        name: "Laravel",
        src: "https://cdn.simpleicons.org/laravel/FF2D20",
        link: "https://laravel.com",
    },
    {
        name: "GitHub",
        src: "https://cdn.simpleicons.org/github/181717",
        link: "https://github.com",
    },
    {
        name: "Jenkins",
        src: "https://cdn.simpleicons.org/jenkins/D24939",
        link: "https://www.jenkins.io",
    },
    {
        name: "Cloud",
        src: "https://cdn.simpleicons.org/icloud",
        link: "https://cloud.google.com",
    },
    {
        name: "Microservices",
        src: "https://cdn.simpleicons.org/mockserviceworker",
        link: "https://en.wikipedia.org/wiki/Microservices",
    },
    {
        name: "React Native",
        src: "https://cdn.simpleicons.org/react/61DAFB",
        link: "https://reactnative.dev",
    },
    {
        name: "HTML5",
        src: "https://cdn.simpleicons.org/html5/E34F26",
        link: "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5",
    },
    {
        name: "CSS3",
        src: "https://cdn.simpleicons.org/css3/1572B6",
        link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    },
    {
        name: "JavaScript",
        src: "https://cdn.simpleicons.org/javascript/F7DF1E",
        link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    },
    {
        name: "Bootstrap",
        src: "https://cdn.simpleicons.org/bootstrap/7952B3",
        link: "https://getbootstrap.com",
    },
    {
        name: "WordPress",
        src: "https://cdn.simpleicons.org/wordpress/21759B",
        link: "https://wordpress.org",
    },
    {
        name: "WooCommerce",
        src: "https://cdn.simpleicons.org/woocommerce/96588A",
        link: "https://woocommerce.com",
    },
    {
        name: "Python",
        src: "https://cdn.simpleicons.org/python/3776AB",
        link: "https://www.python.org",
    },
    {
        name: "Node.js",
        src: "https://cdn.simpleicons.org/nodedotjs/339933",
        link: "https://nodejs.org",
    },
    {
        name: "PHP",
        src: "https://cdn.simpleicons.org/php/777BB4",
        link: "https://www.php.net",
    },
    {
        name: "AWS",
        src: "https://cdn.simpleicons.org/amazonwebservices",
        link: "https://aws.amazon.com",
    },
    {
        name: "Google Cloud",
        src: "https://cdn.simpleicons.org/googlecloud/4285F4",
        link: "https://cloud.google.com",
    },
    {
        name: "Firebase",
        src: "https://cdn.simpleicons.org/firebase/FFCA28",
        link: "https://firebase.google.com",
    },
    {
        name: "AI",
        src: "https://cdn.simpleicons.org/openai",
        link: "https://openai.com",
    },
    {
        name: "Machine Learning",
        src: "https://cdn.simpleicons.org/tensorflow/FF6F00",
        link: "https://www.tensorflow.org",
    },
    {
        name: "Data Science",
        src: "https://cdn.simpleicons.org/pandas/150458",
        link: "https://pandas.pydata.org",
    },
];

const IconCloud = () => {
    return (
        <div className='flex justify-center'>
            <Cloud>
                {icons.map((icon, index) => (
                    <a
                        key={index}
                        href={icon.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={icon.src}
                            alt={icon.name}
                            title={icon.name}
                            height="150"
                            width="150"
                        />
                    </a>
                ))}
            </Cloud>
        </div>
    );
};

export default IconCloud;
