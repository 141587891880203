import { useEffect, useState } from 'react'
import axios from 'axios';
import { Skeleton } from 'antd';



const Footer = () => {
    const [footer, setFooter] = useState({})
    // const [contacts, setContacts] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        axios.get('pages.php/get-pages/65')
            .then((response) => {
                // console.log(response.data.acf)
                setFooter(response.data.acf)
                setLoading(false)
            })
            .catch((err) => { console.log(err) });
    }

    return (
        <div id='contact'>
            {
                loading ?
                    <>
                        <div className='p-[50px] bg-secondarylight'>
                            <div className='page-container'>
                                <Skeleton active title={false} paragraph={{ rows: 5 }} />
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='pt-[50px] bg-secondarylight'>
                            <div className='pb-[40px] page-container'>
                                <p className='font-secondary text-heading text-center py-[5px]'>{footer.heading_contact}</p>
                                <div className='flex justify-center pb-[10px]'>
                                    <div className='w-[50px] border-[3px] border-secondary rounded' />
                                </div>
                                <p className='font-primary text-body text-center py-[5px]'>{footer.writeup_contact}</p>
                            </div>

                            <div className='pb-[30px] page-container'>
                                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 pb-[50px]'>
                                    <>
                                        {
                                            footer.details_contact.map((contact, index) => {
                                                return (
                                                    <div className='p-[10px]' key={contact.heading_details_contact + index}>
                                                        <p className='font-secondary text-subheading text-semibold text-center py-[5px]'>{contact.heading_details_contact}</p>
                                                        <div className='flex justify-center pb-[10px]'>
                                                            <div className='w-[50px] border-[3px] border-secondary rounded' />
                                                        </div>
                                                        <a href={contact.url_details_contact}>
                                                            <p className='font-primary text-[20px] text-center py-[5px]'>
                                                                {contact.details_details_contact}
                                                            </p>
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                </div>
                            </div>
                        </div>
                    </>
            }
            <div className='p-[10px] bg-primary'>
                <p className='text-center text-white'>© Copyright Connected Bytes. All Rights Reserved.</p>
            </div>
        </div>
    )
}

export default Footer