import React, { useState } from 'react';
import { Link } from "react-router-dom";
// import { AppshrefreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Col, Row } from 'antd';


const items = [
    {
        label: (
            <Link to='/' >
                <h4 className='text'>Home</h4>
            </Link>
        ),
        key: 'home',
    },
    {
        label: (
            <Link to='/case-studies' >
                <h4 className='text'>Case Studies</h4>
            </Link>
        ),
        key: 'casestudies',
    },
    {
        label: (
            <a href="/#about">
                <h4 className='text'>About</h4>
            </a>
        ),
        key: 'about',
    },
    {
        label: (
            <a href="/#services">
                <h4 className='text'>Services</h4>
            </a>
        ),
        key: 'services',
    },
    {
        label: (
            <a href="/#clients">
                <h4 className='text'>Clients</h4>
            </a>
        ),
        key: 'clients',
    },
    {
        label: (
            <a href="/#team">
                <h4 className='text'>Team</h4>
            </a>
        ),
        key: 'team',
    },

    {
        label: (
            <a href="/#contact">
                <h4 className='text'>Contact</h4>
            </a>
        ),
        key: 'contact',
    },
];

const Header = () => {
    const [current, setCurrent] = useState('home');
    const onClick = (e) => {
        // console.log('click ', e);
        setCurrent(e.key);
    };

    return (
        <div>
            <div className='page-container'>
                <Row gutter={5} className='pt-[5px] pb-[10px]'>
                    <Col xs={6} md={4}>
                        <img src="https://utfs.io/f/wzoc0SJJ8iMqmv8tcTUCV0hUWxjMFoDS38r1AKmZ5kftGwsN" width={100} alt='ConnectedBytes Logo' />
                    </Col>
                    <Col xs={18} md={20} className='pt-[5px]'>
                        <Menu className='font-secondary flex justify-end items-center' onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
                    </Col>
                </Row>
                {/* <div className='flex justify-end'> */}
                {/* </div> */}
            </div>
        </div>
    )
}

export default Header