import { useEffect, useState } from 'react'
import Splash from '../components/Splash';
import IconCloud from '../components/IconCloud';
import { Anchor, Layout, Menu, Carousel, Spin } from 'antd';
import axios from 'axios';
import ImageLoader from '../components/ImageLoader';


const Home = () => {
    const [count, setCount] = useState(0)
    const [pagedata, setPagedata] = useState({})
    const [loading, setLoading] = useState(true)
    const [sliders, setSliders] = useState([])
    const [about, setAbout] = useState([])
    const [services, setServices] = useState([])
    const [skills, setSkills] = useState({})
    const [clients, setClients] = useState({})
    const [team, setTeam] = useState({})

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        axios.get('pages.php/get-pages/15')
            .then((response) => {
                // console.log(response.data.acf)
                setPagedata(response.data.acf)
                setSliders(response.data.acf.sliders_home)
                setAbout(response.data.acf.about_us_box_home.items_about_us_box_home)
                setServices(response.data.acf.services_box_home.items_services_box_home)
                setSkills(response.data.acf.skills_home)
                setClients(response.data.acf.clients_home)
                setTeam(response.data.acf.team_home)
                setLoading(false)
            })
            .catch((err) => { console.log(err) });
    }

    return (
        <>
            {
                loading ?
                    <Splash />
                    :

                    <div>
                        <Layout className='page-container bg-white'>
                            <div>

                                {/* slider */}
                                <div id='home' className='home-slider pb-[50px]'>
                                    <Carousel infinite autoplay effect='fade' arrows dots>
                                        {
                                            sliders.map((slider, index) => {
                                                return (
                                                    <div className='p-5' key={index}>
                                                        <ImageLoader url={slider.slider_image_url_home} ht={450} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>
                                </div>

                                {/* mission vison */}
                                <div id='about'>
                                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 pb-[50px]'>
                                        {
                                            about.map((about, index) => {
                                                return (
                                                    <div key={about.heading_about_us_home + index} className='flex flex-col border-secondary rounded border-[2px] bg-white shadow-md pb-[20px]'>
                                                        <ImageLoader url={about.image_about_us_home} ht={200} />
                                                        <p className='text-subheading text-center pt-[20px] pb-[5px] font-secondary font-semibold px-[20px]'>{about.heading_about_us_home}</p>
                                                        <div className='flex justify-center pb-[10px]'>
                                                            <div className='w-[50px] border-[3px] border-secondary rounded' />
                                                        </div>
                                                        <p className='text-body text-left pt-[20px] pb-[5px] font-primary px-[20px]'>{about.writeup_about_us_home}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                {/* services */}
                                <div id='services'>
                                    <div className='pb-[15px]'>
                                        <p className='font-secondary text-heading text-center py-[5px]'>Our Services</p>
                                        <div className='flex justify-center pb-[10px]'>
                                            <div className='w-[50px] border-[3px] border-secondary rounded' />
                                        </div>
                                    </div>

                                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 pb-[50px]'>
                                        {
                                            services.map((service, index) => {
                                                return (
                                                    <div key={service.heading_services_home + index} className='flex flex-col border-secondary rounded border-[2px] bg-white shadow-md pb-[20px]'>
                                                        <div className='flex justify-center items-center p-2'>
                                                            {/* <img src={service.image_services_home} height={100} /> */}
                                                            <ImageLoader url={service.image_services_home} ht={30} />
                                                        </div>
                                                        <p className='text-subheading text-center pt-[20px] pb-[5px] font-secondary font-semibold px-[20px]'>{service.heading_services_home}</p>
                                                        <div className='flex justify-center pb-[10px]'>
                                                            <div className='w-[50px] border-[3px] border-secondary rounded' />
                                                        </div>
                                                        <p className='text-body text-left pt-[20px] pb-[5px] font-primary px-[20px]'>{service.writeup_services_home}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                {/* skills */}
                                <div>
                                    <div className='pb-[50px]'>
                                        <div className='pb-[5px]'>
                                            <p className='font-secondary text-heading text-center py-[5px]'>{skills.heading_skills_home}</p>
                                            <div className='flex justify-center pb-[10px]'>
                                                <div className='w-[50px] border-[3px] border-secondary rounded' />
                                            </div>
                                            <p className='font-primary text-body text-center py-[5px]'>{skills.writeup_skills_home}</p>
                                        </div>
                                        <IconCloud />
                                    </div>
                                </div>

                                {/* clients */}
                                <div id='clients' className='pb-[50px]'>
                                    <div className='pb-[5px]'>
                                        <p className='font-secondary text-heading text-center py-[5px]'>{clients.heading_skills_home}</p>
                                        <div className='flex justify-center pb-[10px]'>
                                            <div className='w-[50px] border-[3px] border-secondary rounded' />
                                        </div>
                                        <p className='font-primary text-body text-center py-[5px]'>{clients.writeup_skills_home}</p>
                                    </div>

                                    {/* responsive slider using tailwindcss */}
                                    <div className="desktop"> {/* Desktop */}
                                        <Carousel slidesToShow={4} autoplay infinite dots={false}>
                                            {
                                                clients.logos_skills_home.map((client, index) => {
                                                    return (
                                                        <div className='p-2' key={index}>
                                                            {/* <img src={client.logo_logos_skills_home} /> */}

                                                            <ImageLoader url={client.logo_logos_skills_home} ht={10} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    </div>

                                    <div className="tablet"> {/* Tablet */}
                                        <Carousel slidesToShow={2} autoplay infinite dots={false}>
                                            {
                                                clients.logos_skills_home.map((client, index) => {
                                                    return (
                                                        <div className='p-5' key={index}>
                                                            {/* <img src={client.logo_logos_skills_home} /> */}

                                                            <ImageLoader url={client.logo_logos_skills_home} ht={10} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    </div>

                                    <div className="mobile"> {/* Mobile */}
                                        <Carousel slidesToShow={1} autoplay infinite dots={false}>
                                            {
                                                clients.logos_skills_home.map((client, index) => {
                                                    return (
                                                        <div className='p-5 flex justify-center' key={index}>
                                                            {/* <img src={client.logo_logos_skills_home} /> */}

                                                            <ImageLoader url={client.logo_logos_skills_home} ht={100} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    </div>
                                </div>

                                {/* team members */}
                                <div id='team'>
                                    <div className='pb-[15px]'>
                                        <p className='font-secondary text-heading text-center py-[5px]'>{team.heading_skills_home}</p>
                                        <div className='flex justify-center pb-[10px]'>
                                            <div className='w-[50px] border-[3px] border-secondary rounded' />
                                        </div>
                                        <p className='font-primary text-body text-center py-[5px]'>{team.writeup_skills_home}</p>
                                    </div>

                                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 pb-[50px]'>
                                        {
                                            team.members_home.map((team, index) => {
                                                return (
                                                    <div key={team.name_member_home + index} className='flex flex-col border-secondary rounded border-[2px] bg-white shadow-md pb-[20px]'>
                                                        <img src={team.image_member_home} />
                                                        <p className='text-subheading text-center pt-[20px] pb-[5px] font-secondary font-semibold px-[20px]'>{team.name_member_home}</p>
                                                        <div className='flex justify-center pb-[10px]'>
                                                            <div className='w-[50px] border-[3px] border-secondary rounded' />
                                                        </div>
                                                        <p className='text-body text-center pt-[5px] pb-[5px] font-primary px-[20px]'>{team.designation_member_home}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                        </Layout >
                    </div>
            }
        </>
    )
}

export default Home